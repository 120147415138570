import React, { useEffect } from 'react';
import { Location, navigate } from '@reach/router';
import queryString from 'query-string';
import Layout from '../components/shared/layout';

const withLocation = (ComponentToWrap) => (props) => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        query={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
);

const Redirect301Template = ({ pageContext: { redirectUrl: defaultUrl } }) => {
  // REFACTOR
  // Should be much better solution
  useEffect(() => {
    navigate(defaultUrl);
  }, [defaultUrl]);
  return (
    <Layout>
      <h1>Page Moved Permanently</h1>
      <p>
        301 Redirect to <a href={defaultUrl}>{defaultUrl}</a>
      </p>
    </Layout>
  );
};
export default withLocation(Redirect301Template);
